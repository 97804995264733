import VoiceIcon from "../../assets/Group 171.png";
import MicIcon from "../../assets/svg/videoMicIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import Webcam from "react-webcam";
import AWS from "aws-sdk";
import Lottie from "react-lottie";
import { useNetworkState } from "react-use";
import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { toast } from "react-toastify";
import TimerCounterWithProgress from "../../components/timerCounterWithProgress";
import { v4 as uuidv4 } from "uuid";
import ModuleConfirmationModal from "../../components/Modals/confirmationModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAssessmentsSelector,
} from "../../store/slices/dashboard-slice/dashboard-selectors";
import {
  getModuleSubmissionDispatcher,
  getUserActivityDispatcher,
  setLoadingDispatcher,
} from "../../store/slices/dashboard-slice/dashboard-dispatchers";
import useUserActivityDetection from "../../hooks/miscellaneousActivityDetection";
import CustomToaster from "../../components/Modals/CustomToaster";
import ExitFullScreenModal from "../../components/Modals/exitFullScreen";
import screenfull from "screenfull";
import InternetModal from "../../components/Modals/internetModal";
import moment from "moment";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import InternetSpeedModal from "../../components/Modals/internetSpeedModal";
import TabChangeDetectionModal from "../../components/Modals/tabChangeDetected";
import { ReactSVG } from "react-svg";
import QuickStartModal from "../../components/Modals/quickStartModal";
import { detectBrowser } from "../../utils";
import CustomSpeedChecker from "../../components/Modals/CustomSpeedChecker";
import aiSpeaking from "../../assets/lottie/aiSpeaking.json";
import aiListening from "../../assets/lottie/aiListening.json";

const width = 650;
const height = 650;
let partNumber = 1;
let multipartMap: any = { Parts: [] };
const bucketName = "masters-unoin";
const key = `live-video/${Date.now()}.webm`;

AWS.config.update({
  accessKeyId: "AKIAXYKJWKKMP33E5KUS",
  secretAccessKey: "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",
  region: "ap-south-1",
});

const s3 = new AWS.S3();

const VideoTest = () => {
  const navigate = useNavigate();
  const { assessmentId, testId, userId } = useParams();
  const chatEndRef: any = useRef(null);
  const myAssessments = useAppSelector(getAssessmentsSelector);
  const dispatcher = useAppDispatch();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [liveVideoMediaRecorder, setLiveVideoMediaRecorder] = useState<
    MediaRecorder | any
  >(null);
  // const assessmentModule = useAppSelector(getAssessmentModuleSelector);
  const [submitTestModal, setSubmitTestModal] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState<number>(0);
  // Thinking = 0 Lisneting = 1 Speaking = 2
  const [isRecording, setIsRecording] = useState(true);
  const [cameraStats, setCameraStats] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [isExitFullScreen, setIsExitFullScreen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [conversationAns, setConversationAns] = useState<string>("");
  const [moduleQuestions, setModuleQuestions] = useState<any>([]);
  const [aiChats, setAIChat] = useState<any>([]);
  const [isToasterDisplayed, setIsToasterDisplayed] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [networkChecking, setNetworkChecking] = useState(false);
  const [assessmentModule, setAssessmentModule] = useState<any>({});
  const [isInternet5Mb, setIsInternet5Mb] = useState(true);
  const [moduleTime, setModuleTime] = useState(0);
  const [tabSwitchDetected, setTabSwitchDetected] = useState(false);
  const [quickStartInSafari, setQuickStartInSafari] = useState(false);

  const state = useNetworkState();
  let internetTimer: any = null;
  let speedTimer: any = null;

  useUserActivityDetection();
  // const audioElement = new Audio();
  let audioElement = useRef(new Audio());
  let speakTimeout: any = null;
  let toasterTimeout: any = null;
  let streamRef: any = useRef(null);
  let AISpeakingRef: any = useRef(null);
  let AIChatDataRef: any = useRef(null);
  const uploadIdRef: any = useRef("");

  let audioStatus: any = useRef(0);
  let audioList: any = useRef([]);
  let audioIndex: any = useRef(0);

  const { webcamRef, isLoading, detected, facesDetected }: any =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),
      camera: ({ mediaSrc, onFrame }: CameraOptions) =>
        new Camera(mediaSrc, {
          onFrame,
          width,
          height,
        }),
    });
  useEffect(() => {
    if (!detected && !isToasterDisplayed && cameraStats && cameraReady) {
      setToastMsg("Face not detected");
      displayToasterFun();
      updateUserActivity("faceNotDetected");
    }
    if (
      facesDetected > 1 &&
      !isToasterDisplayed &&
      cameraStats &&
      cameraReady
    ) {
      setToastMsg("Multiple face detected");
      displayToasterFun();
      updateUserActivity("MultipleFaceDetected");
    }
  }, [detected, facesDetected, cameraStats, cameraReady]);
  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", handleFullscreenChange);
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", handleFullscreenChange);
      }
    };
  }, []);

  useEffect(() => {
    if (screenfull.isEnabled && !screenfull.isFullscreen) {
      checkScreenExit();
    }
  }, [screenfull, screenfull.isFullscreen, screenfull.isEnabled]);

  const checkScreenExit = () => {
    const time = sessionStorage.getItem("screen-exit-time");
    if (time) {
      const seconds = moment().diff(moment(time), "seconds");
      if (seconds > 2) {
        setIsExitFullScreen(true);
        updateUserActivity("exitFullScreen");
      }
    }
  };

  const handleFullscreenChange = () => {
    if (!screenfull.isFullscreen) {
      setIsExitFullScreen(true);
      updateUserActivity("exitFullScreen");
    }
  };

  useEffect(() => {
    if (state) {
      checkInternet(state?.online);
    }
  }, [state]);

  const clearStoredSession = () => {
    sessionStorage.setItem(`${testId}-${userId}`, "");
    sessionStorage.setItem(`txp-${testId}-${userId}`, "0");
    sessionStorage.setItem("screen-exit-time", "");
  };

  const checkInternet = (isInternet: any) => {
    clearTimeout(internetTimer);
    if (isInternet) {
      setNetworkChecking(false);
    } else {
      setNetworkChecking(true);
      setIsInternet5Mb(true);
      clearTimeout(speedTimer);
      internetTimer = setTimeout(() => {
        goBack();
      }, 200000);
    }
  };

  const fullScreenElev: any = document.getElementById("fullscreenDiv");
  const onExitAction = (type: any) => {
    if (type === "cancel") {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        screenfull.request(fullScreenElev);
      }
    }
    if (type === "exit") {
      // submitTest();
      stopRecording();
    }
    setIsExitFullScreen(false);
  };

  const displayToasterFun = () => {
    clearTimeout(toasterTimeout);
    toasterTimeout = setTimeout(() => {
      setIsToasterDisplayed(false);
    }, 1000);
    setIsToasterDisplayed(true);
  };

  useEffect(() => {
    scrollToBottom();
    AIChatDataRef.current = aiChats
  }, [aiChats]);

  useEffect(() => {
    audioStatus.current = 0
    audioList.current = []
    audioIndex.current = 0
    const res = sessionStorage.getItem(`${testId}-${userId}`);
    const time = sessionStorage.getItem(`txp-${testId}-${userId}`);
    if (res) {
      const assessmentTestData: any = JSON.parse(
        decodeURIComponent(escape(atob(res)))
      );
      console.log("assessmentTestData=>", assessmentTestData);
      setAssessmentModule(assessmentTestData);
      if (assessmentTestData?.module?.question) {
        const questions = assessmentTestData?.module?.question?.map(
          (v: any) => {
            return { ...v, answer: v?.answer ? v?.answer : "" };
          }
        );
        setModuleQuestions(questions);
      }
    } else {
      setTimeout(() => {
        goBack();
      }, 0);
    }
    if (time) {
      setModuleTime(Number(time));
    }
  }, []);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function handleVisibilityChange () {
    if (document?.hidden) {
      updateUserActivity("tabChangeDetected");
      setTabSwitchDetected(true);
    }
  }
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const updateUserActivity = (type: string) => {
    dispatcher(
      getUserActivityDispatcher({
        candidateId: userId,
        type: type,
      })
    );
  };

  useEffect(() => {
    setQuickStartInSafari(detectBrowser() === "Safari");
    const initMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream;
        const recorder = new MediaRecorder(stream, {
          mimeType:
            detectBrowser() === "Safari"
              ? "audio/mp4"
              : "audio/webm; codecs=opus",
        });
        setMediaRecorder(recorder);
      } catch (error) {
        console.log('ERR', error)
      }
    };
    initMedia();
  }, []);

  // Live video recording
  useEffect(() => {
    const initMedia = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      const recorder = new MediaRecorder(stream, {
        mimeType:
          detectBrowser() === "Safari" ? "video/mp4" : "video/webm; codecs=vp8",
      });
      setLiveVideoMediaRecorder(recorder);
    };
    initMedia();
  }, []);

  useEffect(() => {
    if (
      mediaRecorder &&
      userId &&
      moduleQuestions &&
      myAssessments &&
      !quickStartInSafari
    ) {
      const newSocket = io("wss://talorexvoice.com/socket.io", {
        query: {
          userId: userId,
        },
        transports: ["websocket"],
      });
      newSocket.on("connect_error", (error) => {
        console.error("Connection error:=>", error);
      });
      newSocket.on("disconnect", (reason, details) => {
        console.error("disconnect error:=>", reason, details);
      });
      // console.log("socket id", newSocket.id);
      newSocket.on("connect", () => {
        setIsSocketConnected(true);
        setTimeout(() => {
          setCameraReady(true);
        }, 4000);
        console.log("connected to server", {
          title: moduleQuestions?.[0]?.title,
          name: myAssessments && myAssessments?.[0]?.name,
        });
        newSocket.emit("prompt", {
          title: moduleQuestions?.[0]?.title,
          name: myAssessments && myAssessments?.[0]?.name,
        });
        mediaRecorder.start(200);
        mediaRecorder.ondataavailable = async (event) => {
          if (event?.data?.size > 0) {
            try {
              const arrayBuffer = await event.data.arrayBuffer();
              const base64String = arrayBufferToBase64(arrayBuffer);
              console.log("START AI");
              newSocket.emit("media", { payload: base64String });
            } catch (err) {
              console.error("Failed to encode audio:", err);
              setError("Failed to encode audio.");
            }
          }
        };
        newSocket.emit("start", { streamSid: newSocket.id, callSid: uuidv4() });
      });
      newSocket.on("pauseAudio", (data) => {
        if (data?.stop && AISpeakingRef?.current) {
          setIsSpeaking(1);
          console.log('pauseAudio=>****************************')
          if (AISpeakingRef?.current) {
            let flag = true
            let updatedArray: any = []
            for (let index = AIChatDataRef?.current?.length - 1; index >= 0; index--) {
              if (AIChatDataRef?.current?.[index]?.type === "AI" && flag) {
              } else {
                flag = false
                updatedArray = [AIChatDataRef?.current?.[index], ...updatedArray]
              }
            }
            setAIChat((prev: any) => {
              return [...updatedArray];
            });
          }
          AISpeakingRef.current = false
          audioElement?.current?.pause();
          audioElement.current.currentTime = 0;
          audioElement.current.src = "";
          audioStatus.current = 0
          audioList.current = []
          audioIndex.current = 0
        }
      });
      newSocket.on("question", (data) => {
        if (data?.title?.trim()) {
          setAIChat((prev: any) => {
            return [...prev, { type: "AI", text: data?.title }];
          });
        }
        // setConversationAns((prev) => {
        //   return prev + `AI:${data?.title} `;
        // });
      });
      newSocket.on("answer", (data) => {
        console.log('ANS---------------------', data?.answer, AISpeakingRef?.current)
        if (AISpeakingRef?.current) {
          let flag = true
          let updatedArray: any = []
          for (let index = AIChatDataRef?.current?.length - 1; index >= 0; index--) {
            if (AIChatDataRef?.current?.[index]?.type === "AI" && flag) {
            } else {
              flag = false
              updatedArray = [AIChatDataRef?.current?.[index], ...updatedArray]
            }
          }
          setAIChat((prev: any) => {
            return [...updatedArray, { type: "User", text: data?.answer }];
          });
        } else {
          setAIChat((prev: any) => {
            return [...prev, { type: "User", text: data?.answer }];
          });
        }
        AISpeakingRef.current = false
        audioElement?.current?.pause();
        audioElement.current.currentTime = 0;
        audioStatus.current = 0
        audioList.current = []
        audioIndex.current = 0
        setIsSpeaking(0);
        // setConversationAns((prev) => {
        //   return prev + `User:${data?.answer} `;
        // });
      });

      newSocket.on("audioData", async (data) => {
        const audioBlob = base64ToBlob(data.audio, "audio/mpeg");
        const audioUrl: any = URL.createObjectURL(audioBlob);
        clearTimeout(speakTimeout);
        speakTimeout = setTimeout(() => {
          if (audioList?.current?.length) {
            audioList.current = [...audioList.current, audioUrl]
          } else {
            audioList.current = [audioUrl]
          }
          setIsSpeaking(2);
          AISpeakingRef.current = true
          console.log('CCC----', audioStatus.current, audioIndex.current, audioList.current)
          if (audioStatus?.current === 0 && audioList?.current?.length) {
            audioStatus.current = 1
            playAudioFile()
          }
        }, 0);

        // speakTimeout = setTimeout(() => {
        //   audioElement.current.src = audioUrl;
        //   audioElement.current.play();
        // }, 200);
        audioElement.current.onended = () => {
          console.log('ENMDEEDDD----', audioIndex.current, audioList?.current?.length)
          if (audioList?.current?.length - 1 === audioIndex?.current) {
            audioStatus.current = 0
            audioList.current = []
            audioIndex.current = 0
            audioElement?.current?.pause();
            audioElement.current.currentTime = 0;
            audioElement.current.src = "";
            setIsSpeaking(1);
            AISpeakingRef.current = false
          } else {
            audioIndex.current = audioIndex?.current + 1
            audioStatus.current = 0
            if (audioStatus?.current === 0 && audioList?.current?.length) {
              AISpeakingRef.current = true
              audioStatus.current = 1
              playAudioFile()
            }
          }
        };
      });

      newSocket.on("disconnect", () => {
        console.log("socket: disconnected from server");
      });

      newSocket.on("error", (error: string) => {
        console.error("WebSocket error:", error);
        setError("A WebSocket error occurred.");
      });
      return () => {
        mediaRecorder?.stop();
        audioElement?.current?.pause();
        audioElement.current.currentTime = 0;
        mediaRecorder.removeEventListener("dataavailable", () => { });
        newSocket?.disconnect();
        if (streamRef?.current) {
          streamRef.current
            ?.getTracks()
            ?.forEach((track: any) => track?.stop());
          streamRef.current = null;
        }
      };
    }
  }, [
    mediaRecorder,
    userId,
    moduleQuestions,
    myAssessments,
    quickStartInSafari,
  ]);

  const playAudioFile = () => {
    try {
      console.log("PLAY----", audioIndex?.current, audioStatus?.current, audioList?.current)
      if (audioList?.current?.length - 1 >= audioIndex?.current && audioElement?.current && AISpeakingRef?.current) {
        audioElement.current.src = audioList.current[audioIndex.current];
        audioElement?.current?.play()?.then(() => {
          console.log("Audio is playing");
        }).catch(error => {
          console.log("Play request was interrupted", error);
        });
      }
    } catch (error) {
      console.log('error=>', error)
    }
  }

  useEffect(() => {
    const mediaListner = async () => {
      liveVideoMediaRecorder.ondataavailable = async (event: any) => {
        if (event.data?.size > 0) {
          await uploadChunk(event.data);
        }
      };
      liveVideoMediaRecorder?.start(25000); // Collect data every second
      setIsRecording(true);
      try {
        // Initiate multipart upload
        const createMultipartUpload = await s3
          .createMultipartUpload({
            Bucket: bucketName,
            Key: key,
            ContentType: "video/webm",
          })
          .promise();
        uploadIdRef.current = createMultipartUpload.UploadId;
        // console.log("Multipart upload initiated:", createMultipartUpload);
      } catch (err) {
        console.error("Error initiating multipart upload:", err);
      }
    };
    if (liveVideoMediaRecorder) {
      mediaListner();
    }
  }, [liveVideoMediaRecorder]);

  async function uploadChunk (blob: any) {
    const params = {
      Body: blob,
      Bucket: bucketName,
      Key: key,
      PartNumber: partNumber,
      UploadId: uploadIdRef.current,
    };
    // console.log("params=>", params, uploadIdRef.current);
    try {
      const uploadPart = await s3.uploadPart(params).promise();
      // console.log("UploadPart response:", uploadPart);
      if (uploadPart && uploadPart.ETag) {
        multipartMap.Parts.push({
          ETag: uploadPart.ETag,
          PartNumber: partNumber,
        });
        partNumber++;
        console.log("Uploaded part:", partNumber - 1);
      } else {
        console.error("ETag is undefined in uploadPart response:", uploadPart);
      }
    } catch (err) {
      console.error("Error uploading part:", err);
    }
  }

  async function stopRecording () {
    dispatcher(setLoadingDispatcher(true));
    liveVideoMediaRecorder?.stop();
    clearTimeout(speakTimeout);
    audioElement?.current?.pause();
    audioElement.current.currentTime = 0;
    audioElement.current.src = "";
    audioStatus.current = 0
    audioList.current = []
    audioIndex.current = 0
    webcamRef?.current?.video?.srcObject
      ?.getTracks()
      ?.forEach((track: any) => track?.stop());
    if (webcamRef && webcamRef?.current) {
      webcamRef.current.video.srcObject = null;
    }
    setIsSpeaking(1);
    AISpeakingRef.current = false
    if (streamRef?.current) {
      streamRef.current?.getTracks()?.forEach((track: any) => {
        track?.stop();
        streamRef.current?.removeTrack(track);
      });
      streamRef.current = null;
    }
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    try {
      // Complete multipart upload
      const completeMultipartUpload = await s3
        .completeMultipartUpload({
          Bucket: bucketName,
          Key: key,
          UploadId: uploadIdRef.current,
          MultipartUpload: multipartMap,
        })
        .promise();
      submitTest(completeMultipartUpload?.Location || "");
      // console.log("Multipart upload completed:", completeMultipartUpload);
    } catch (err) {
      console.error("Error completing multipart upload:", err);
      submitTest("");
    }
  }

  function arrayBufferToBase64 (buffer: ArrayBuffer): string {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  const base64ToBlob = (base64: string, type: string): Blob => {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type });
  };

  // useEffect(() => {
  //   if (mediaRecorder && mediaRecorder.state !== "inactive") {
  //     if (isRecording) {
  //       mediaRecorder.resume();
  //     } else {
  //       mediaRecorder.pause();
  //     }
  //   }
  // }, [isRecording, mediaRecorder]);

  const onTimeout = () => {
    if (Number(assessmentModule?.module?.time) > 0) {
      stopRecording();
    }
  };
  const videoConstraints = {
    facingMode: "user",
  };

  const onSubmitTest = (type: string) => {
    setSubmitTestModal(false);
    if (type === "submit") {
      // submitTest();
      stopRecording();
    }
  };

  const getConversation = () => {
    let ans = ""
    aiChats?.map((v: any) => {
      if (v?.type === "AI") {
        ans = ans + `AI:${v?.text} `
      } else {
        ans = ans + `User:${v?.text} `
      }
    })
    return ans
  }
  const submitTest = async (location: string) => {
    try {
      const res = await dispatcher(
        getModuleSubmissionDispatcher({
          moduleId: testId,
          videoUrl: location,
          question: [{ ...moduleQuestions?.[0], answer: getConversation() }],
        })
      );
      if (res?.payload.data?.status) {
        toast.success(
          `${assessmentModule?.module?.name} completed successfully!`,
          {}
        );
        // navigate(-1);
        // screenfull.exit()
        goBack();
      } else {
        toast.error("Oops! Submission is failed", {});
        dispatcher(setLoadingDispatcher(false));
      }
    } catch (error) {
      toast.error("Oops! Internal server error", {});
      // console.log("error=>", error);
      dispatcher(setLoadingDispatcher(false));
    }
  };

  const onClose = () => {
    setIsInternet5Mb(true);
  };

  const goBack = () => {
    clearStoredSession();
    window.location.replace(`/assessment/${userId}/${assessmentId}/modules`);
  };

  const speedCheckerFun = () => {
    setIsInternet5Mb(false);
    speedTimer = setTimeout(() => {
      setIsInternet5Mb(true);
    }, 30000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aiSpeaking,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsLi = {
    loop: true,
    autoplay: true,
    animationData: aiListening,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <ReactInternetSpeedMeter
        outputType=""
        pingInterval={ 5000 } // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={ 4 }
        imageUrl="https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png"
        downloadSize="500000" //bytes
        callbackFunctionOnNetworkDown={ (data: any) => {
          if (isInternet5Mb) {
            speedCheckerFun();
          }
        } }
        callbackFunctionOnNetworkTest={ (data: any) => {
          if (data >= 4 && !isInternet5Mb) {
            setIsInternet5Mb(true);
            clearTimeout(speedTimer);
          }
        } }
      />
      <div className="sm:p-6 md:px-20 md:py-12 p-4">
        { isToasterDisplayed && (
          <CustomToaster
            message={ toastMsg }
            onClose={ () => {
              setIsToasterDisplayed(false);
            } }
          />
        ) }
        { !isInternet5Mb && <CustomSpeedChecker /> }
        <TimerCounterWithProgress
          timestamp={ moduleTime || 0 }
          title={ "Video Round" }
          onTimeout={ onTimeout }
          showTimer={ true }
          showProgressFromLT={ true }
        />
        <div className="flex">
          <span className="text-[20px] text-black font-sansation font-semibold">
            TALBot is your interviewer, please listen carefully and respond to
            the questions asked by TALBot
          </span>
        </div>
        {/* <div className="flex mb-3">
        <span className="text-[32px] font-semibold font-sansation text-[#CC8448]">
          Case Study
        </span>
      </div> */}
        <div className="flex md:flex-row flex-col md:justify-center mt-8">
          <div className="flex flex-col w-[65%] h-1/2 md:flex-row justify-between">
            {/* <div className="relative flex w-[50%] h-[470px] bg-[#474646] justify-center items-center rounded-xl border border-[#E5A971] mr-4">
              <div className="flex justify-center items-center">
                <div
                  className={`h-10 w-10 md:h-40 md:w-40 bg-white text-[#E5A971] rounded-full text-[20px] md:text-[60px] font-semibold font-sansation flex justify-center items-center ${
                    isSpeaking ? "animation-pulse" : ""
                  }`}
                >
                  Ai
                </div>
              </div>
              <div className="absolute left-6 bottom-4 bg-black opacity-75 text-white font-semibold px-4 py-1 rounded font-sansation">
                Ai Bot
              </div>
              <div className="absolute right-2 bottom-4 text-white  px-4 py-1  ">
                <ReactSVG src={MicIcon} className="h-8 w-10" />
              </div>
            </div> */}
            <div className="flex relative h-1/2 w-full rounded-xl overflow-hidden">
              <div className="flex rounded-xl w-full overflow-hidden h-[500px] bg-gray-200">
                { isSocketConnected && (
                  <Webcam
                    ref={ webcamRef }
                    screenshotFormat="image/jpeg"
                    screenshotQuality={ 1 }
                    // audio={userMute}
                    videoConstraints={ videoConstraints }
                    onUserMedia={ () => {
                      setCameraStats(true);
                    } }
                    onUserMediaError={ () => {
                      setCameraStats(false);
                    } }
                    className="overflow-hidden rounded-xl bg-gray-200 object-cover w-full"
                  />
                ) }
                <div className="absolute left-6 bottom-4 bg-black opacity-75 text-white font-semibold px-4 py-1 rounded font-sansation capitalize">
                  { (myAssessments && myAssessments?.[0]?.name) || "Candidate" }
                </div>
                {/* <div className="absolute right-6 bottom-4 text-white  px-4 py-1  ">
                  <ReactSVG
                    onClick={() => {
                      setUserMute(!userMute);
                    }}
                    src={MicIcon}
                    className="h-8 w-10"
                  />
                </div> */}
                <div className="absolute bottom-4 right-4 flex w-[200px] h-[200px] bg-[#474646] justify-center items-center rounded-xl border-[2px] border-[#CC8448]">
                  <div className="flex justify-center items-center">
                    {/* <div
                      className={`h-10 w-10 md:h-[90px] md:w-[90px] bg-white text-[#E5A971] rounded-full text-[20px] md:text-[36px] font-semibold font-sansation flex justify-center items-center ${
                        isSpeaking ? "animation-pulse" : ""
                      }`}
                    >
                      AI
                    </div> */}
                    { isSpeaking === 2 ? (
                      <Lottie
                        options={ defaultOptions }
                        height={ 120 }
                        width={ 120 }
                      />
                    ) : (
                      <Lottie
                        options={ defaultOptionsLi }
                        height={ 170 }
                        width={ 170 }
                      />
                    ) }
                    { isSpeaking === 2 ? <div
                      className={ `absolute text-[#E5A971] text-[16px] md:text-[24px] font-semibold font-sansation bg-[#474646]` }
                    >
                      AI
                    </div> : <div
                      className={ `absolute text-[#E5A971] text-[8px] md:text-[12px] font-semibold font-sansation bg-[#474646]` }
                    >
                      { isSpeaking === 0 ? "Thinking" : "Listening" }
                    </div> }
                  </div>
                  <div className="absolute left-2 bottom-2 bg-black opacity-75 text-white font-semibold px-3 py-1 text-[10px] rounded-[10px] font-sansation">
                    AI Bot
                  </div>
                  <div className="absolute right-2 bottom-2 text-white">
                    <ReactSVG
                      src={ MicIcon }
                      style={ { width: "20px", height: "20px" } }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[35%] bg-white shadow rounded-lg p-4 ml-4">
            <div className="flex w-full flex-col h-[440px]">
              <div className="flex gap-2 px-2 pb-2">
                <img src={ VoiceIcon } />
                <span className="text-xs text-gray-300 mt-2">CC/Subtitle </span>
              </div>
              <div className="flex flex-col mx-2 bg-white overflow-y-scroll space-y-2">
                { Array.from(
                  new Map(aiChats?.map((itm: any) => [itm?.text, itm])).values()
                )?.map((item: any, index: number) => {
                  if (item?.type === "AI") {
                    return (
                      <div
                        key={ item?.text + index }
                        className="flex flex-col gap-1 w-full max-w-[80%]"
                      >
                        <div className="flex flex-col leading-1.5 p-4 border-gray-200 bg-[#F5F2F2] rounded-xl">
                          <p className="text-sm font-normal text-gray-900">
                            { item?.text }
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={ item?.text + index }
                        className="flex justify-end"
                      >
                        <div className="bg-[#F5F2F2] text-black p-2 rounded-lg max-w-[80%]">
                          { item?.text }
                        </div>
                      </div>
                    );
                  }
                }) }
                <div ref={ chatEndRef } />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end py-6 mt-4 font-sansation">
          <button
            className="flex justify-center bg-[#40B24B] px-12 py-2 rounded-lg text-white font-semibold font-sansation"
            onClick={ () => {
              setSubmitTestModal(true);
            } }
          >
            Submit
          </button>
        </div>
        { submitTestModal ? (
          <ModuleConfirmationModal
            onPress={ (v) => {
              onSubmitTest(v);
            } }
            title={ assessmentModule?.module?.name }
          />
        ) : null }
        { isExitFullScreen ? (
          <ExitFullScreenModal
            onPress={ (v) => {
              onExitAction(v);
            } }
          />
        ) : null }
        { networkChecking && <InternetModal /> }
        {/* { !isInternet5Mb && <InternetSpeedModal onClose={ () => { onClose() } } /> } */ }
        { tabSwitchDetected && (
          <TabChangeDetectionModal
            onPress={ () => {
              setTabSwitchDetected(false);
            } }
          />
        ) }
        { quickStartInSafari && (
          <QuickStartModal
            onClose={ () => {
              setQuickStartInSafari(false);
            } }
          />
        ) }
      </div>
    </>
  );
};

export default VideoTest;
